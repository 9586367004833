import { FollowMutation } from '../components/CommunitySection/CommunitySection';
import { queryAPI } from '../utils/queryApi';

interface FollowResult {
  followeeUserId: string;
  followerUserId: string;
  data: { [key: string]: boolean };
}

export async function updateFollowingFeeds(
  followMutation: FollowMutation,
  followeeUserIds: string[],
  followerUserIds: string[]
): Promise<FollowResult[]> {
  const mutationName =
    followMutation === 'follow' ? 'followFeed' : 'unfollowFeed';
  const mutation = `
    mutation ModifyFeed($followeeUserId: ID!, $followerUserId: ID!) {
      ${mutationName}(
        followeeUserId: $followeeUserId
        followerUserId: $followerUserId
      )
    }
  `;

  // Use a nested map to create all combinations of followees and followers
  const results = await Promise.all(
    followeeUserIds.flatMap((followeeUserId) =>
      followerUserIds.map((followerUserId) =>
        queryAPI<{ [key: string]: boolean }>(mutation, {
          followeeUserId,
          followerUserId,
        }).then((result) => ({
          followeeUserId,
          followerUserId,
          data: result.data,
        }))
      )
    )
  );

  return results;
}
