import React, { useState } from 'react';
import styled from 'styled-components';
import { Heading3 } from '../Theme/Theme';
import { StyledRedButton } from '../Button/Button';
import { TextInput } from 'carbon-components-react';
import { deleteCommunityActivity } from '../../mutations/deleteCommunityActivity';

type Props = {
  userId: string;
};

const CommunityActivitySection: React.FC<Props> = ({ userId }) => {
  const [activityId, setActivityId] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleDeleteActivity = async () => {
    try {
      setIsLoading(true);
      await deleteCommunityActivity(userId, activityId);
      setSuccessMessage('Activity deleted successfully ');
      setActivityId('');
      setErrorMessage('');
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <HeaderSection>
        <Heading3>Activity</Heading3>
        <p>
          Delete community activity posts for this user by entering the activity
          ID in the text field. The activity will no longer appear in the
          user&apos;s or their followers&apos; feeds.
        </p>
      </HeaderSection>
      <ActivityWrapper>
        <InputWrapper>
          <TextInput
            id={'delete-activity-input'}
            labelText={'Activity ID'}
            value={activityId}
            onChange={(e) => setActivityId(e.target.value)}
            onFocus={() => {
              setSuccessMessage('');
              setErrorMessage('');
            }}
          />

          <DeleteButton
            data-testid={'delete-activity-button'}
            onClick={handleDeleteActivity}
            disabled={isLoading}
          >
            Delete activity
          </DeleteButton>
        </InputWrapper>
        {(successMessage || errorMessage) && (
          <ConfirmationMessage>
            {successMessage && (
              <SuccessMessage>{successMessage}</SuccessMessage>
            )}
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </ConfirmationMessage>
        )}
      </ActivityWrapper>
    </Wrapper>
  );
};

export default CommunityActivitySection;

const Wrapper = styled.div`
  margin-top: 80px;
  gap: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const DeleteButton = styled(StyledRedButton)`
  margin: 0px 0px 0px 20px;
`;

const ConfirmationMessage = styled.div`
  margin-top: 16px;
`;

const SuccessMessage = styled.p`
  color: green;
`;

const ErrorMessage = styled.p`
  color: red;
`;
