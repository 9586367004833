import { queryAPI } from '../utils/queryApi';
import gql from 'fake-tag';

export async function deleteCommunityActivity(
  userId: string,
  activityId: string
) {
  const result = await queryAPI<{ deleteCommunityActivity: boolean }>(
    gql`
      mutation DeleteFeedItem($userId: ID!, $feedItemId: ID!) {
        deleteFeedItem(userId: $userId, feedItemId: $feedItemId)
      }
    `,
    { userId, feedItemId: activityId }
  );

  if (result.errors) {
    throw new Error(result.errors[0].message);
  }

  return result;
}
