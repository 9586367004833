import { Loading } from 'carbon-components-react';
import React, { ReactElement } from 'react';
import './styles.scss';
import styled from 'styled-components';

interface LoaderProps {
  small?: boolean;
}

export const Loader = ({ small = false }: LoaderProps): ReactElement => {
  return (
    <Wrapper>
      <Loading
        active
        description="Active loading indicator"
        small={small}
        withOverlay={false}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  .bx--loading__stroke {
    stroke: ${(props): string => props.theme.inspireCobalt};
  }
`;
