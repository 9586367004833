import { isUUID } from '../../../utils/utils';

interface GetIdsFromTextResultSuccess {
  valid: true;
  ids: string[];
  error?: never;
}

interface GetIdsFromTextResultFailure {
  valid: false;
  ids?: never;
  error: string;
}

type ValidationResult =
  | GetIdsFromTextResultSuccess
  | GetIdsFromTextResultFailure;

export const getUserIdsFromText = (input: string): ValidationResult => {
  const allIds = input
    .split(',')
    .map((id) => id.trim())
    .filter(Boolean);
  const validIds = allIds.filter(isUUID);

  if (validIds.length !== allIds.length) {
    const invalidIds = allIds.filter((id) => !isUUID(id));
    return {
      valid: false,
      error: `Invalid user IDs: "${invalidIds.join(
        '", "'
      )}" - Please correct these IDs and try again.`,
    };
  }

  return { valid: true, ids: validIds };
};
