import { queryContentfulAPI } from '../../utils/contentfulApi';

export interface SearchCMSWorkoutResultItem {
  sys: {
    id: string;
  };
  name: string;
  internalName: string;
  duration: number;
  type: string;
  ability: string;
}

export interface SearchCMSWorkoutResult {
  workoutCollection: {
    items: SearchCMSWorkoutResultItem[];
  };
  liveWorkoutCollection: {
    items: SearchCMSWorkoutResultItem[];
  };
}

const searchCMSWorkoutsQuery = /* GraphQL */ `
  query($search: String!) {
    workoutCollection(
      where: {
        OR: [
          { sys: { id: $search } }
          { name_contains: $search }
          { internalName_contains: $search }
        ]
      }
    ) {
      items {
        sys {
          id
        }
        name
        duration
        type
        ability
        internalName
      }
    }
    liveWorkoutCollection(
      where: {
        OR: [
          { sys: { id: $search } }
          { name_contains: $search }
          { internalName_contains: $search }
        ]
      }
    ) {
      items {
        sys {
          id
        }
        name
        duration: length
        type
        ability
        internalName
      }
    }
  }
`;

export const searchCmsWorkouts = async (search: string) => {
  const res = await queryContentfulAPI<SearchCMSWorkoutResult>(
    searchCMSWorkoutsQuery,
    { search }
  );
  return res.data.workoutCollection.items.concat(
    res.data.liveWorkoutCollection.items
  );
};
