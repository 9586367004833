import gql from 'fake-tag';
import { queryAPI } from '../utils/queryApi';

export async function generateFriendUrl(id: string) {
  const result = await queryAPI<{ generateFriendUrl: string }>(
    gql`
      mutation GenerateFriendUrl($id: ID!) {
        generateFriendUrl(id: $id)
      }
    `,
    { id }
  );
  if (result.errors) {
    throw new Error(result.errors[0].message);
  }

  return result.data.generateFriendUrl;
}
