import { Close24 } from '@carbon/icons-react';
import React, { Fragment, useCallback, useEffect } from 'react';
import styled from 'styled-components';

interface Props {
  isOpen: boolean;
  children: React.ReactChild;
  onClose: () => void;
  isLarge?: boolean;
  minHeight?: string;
}

const Modal: React.FC<Props> = ({
  isOpen,
  children,
  onClose,
  isLarge,
  minHeight,
}) => {
  const handleEscape = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose]
  );
  useEffect(() => {
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [handleEscape]);

  return (
    <Fragment>
      <StyledModalContainer
        isOpen={isOpen}
        tabIndex={0}
        onClick={onClose}
      ></StyledModalContainer>
      <StyledModal isOpen={isOpen} isLarge={isLarge} minHeight={minHeight}>
        <Fragment>
          <StyledCloseButton onClick={onClose}>
            <Close24 />
          </StyledCloseButton>
          {children}
        </Fragment>
      </StyledModal>
    </Fragment>
  );
};

const StyledModalContainer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  z-index: ${(props) => (props.isOpen ? '1' : '-1')};
  background-color: rgba(57, 57, 57, 0.3);
`;

const StyledModal = styled.div<{
  isOpen: boolean;
  isLarge?: boolean;
  minHeight?: string;
}>`
  z-index: ${(props) => (props.isOpen ? '2' : '-1')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 5vh;
  width: ${(props) => (props.isLarge ? '900px' : '600px')};
  min-height: ${(props) => (props.minHeight ? props.minHeight : '600px')};
  max-height: ${(props) => (props.isLarge ? '90vh' : '60vh')};
  background-color: white;
  padding: 16px;
  border-radius: 4px;
  overflow-y: scroll;
`;

const StyledCloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 16px;
  padding: 0;
`;

export default Modal;
