import React, { Fragment, useState } from 'react';
import {
  ErrorMessage,
  Heading2,
  Heading3,
  SuccessMessage,
} from '../Theme/Theme';
import styled from 'styled-components';
import { InlineLoading } from 'carbon-components-react';
import { Loader } from '../Loader/Loader';
import Modal from '../Modal/Modal';
import { generateFriendUrl } from '../../mutations/generateFriendUrl';
import { StyledBlueButton } from '../Button/Button';

type Props = {
  userId: string;
};

const CommunityFriendUrlSection: React.FC<Props> = ({ userId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleGenerateUrlClick = async () => {
    try {
      setIsLoading(true);
      const newUrl = await generateFriendUrl(userId);
      setSuccessMessage(`URL successfully updated. The new URL is: ${newUrl}`);
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Wrapper>
      <HeaderSection>
        <Heading3>Generate New Friend URL</Heading3>
        <p>
          Generate and store a new friend URL for the user. The new URL will be
          available in the app for the user to add more friends. <br />
          <br /> Clicking this button will deactivate their old link, so if
          anyone clicks an older URL will see an error that the user could not
          be found.
        </p>
      </HeaderSection>
      <ActivityWrapper>
        {!isLoading ? (
          <ButtonContainer>
            <StyledBlueButton
              data-testid="open-generate-modal-button"
              onClick={(): void => {
                setShowModal(true);
              }}
            >
              Generate
            </StyledBlueButton>
          </ButtonContainer>
        ) : (
          <Loader small />
        )}
        {showModal && (
          <Modal
            isOpen={showModal}
            onClose={() => {
              setShowModal(false);
              setSuccessMessage('');
              setErrorMessage('');
            }}
          >
            <Fragment>
              <Heading2>Generate New Friend URL</Heading2>
              {isLoading && <InlineLoading status="active" />}
              {!successMessage && (
                <>
                  <ModalExplanation>
                    Use this to update the URL for the user. This will
                    deactivate their current URL. We will not be able to
                    reactivate any previous URLs.
                  </ModalExplanation>
                  <ButtonContainer>
                    <StyledBlueButton
                      data-testid={'generate-button'}
                      onClick={() => handleGenerateUrlClick()}
                    >
                      Generate New Friend URL
                    </StyledBlueButton>
                  </ButtonContainer>
                </>
              )}
              {successMessage && (
                <SuccessMessage>
                  <p>{successMessage}</p>
                </SuccessMessage>
              )}
              {errorMessage && (
                <ErrorMessage>
                  <p>{errorMessage}</p>
                </ErrorMessage>
              )}
            </Fragment>
          </Modal>
        )}
      </ActivityWrapper>
    </Wrapper>
  );
};

export default CommunityFriendUrlSection;

const Wrapper = styled.div`
  margin-top: 80px;
  gap: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ActivityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ModalExplanation = styled.p`
  margin-bottom: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
