import React, { useState } from 'react';
import Modal from '../Modal/Modal';
import { TextArea } from 'carbon-components-react';
import styled from 'styled-components';
import { updateFollowingFeeds } from '../../mutations/updateFollowingFeeds';
import { StyledBlueButton } from '../Button/Button';
import { FollowAction } from '../CommunitySection/CommunitySection';
import { Heading2 } from '../Theme/Theme';
import { Loader } from '../Loader/Loader';
import { getUserIdsFromText } from './lib/getUserIdsFromText';

type Props = {
  followAction: FollowAction | null;
  setFollowAction: (followAction: FollowAction | null) => void;
};

const FollowerRelationshipModal: React.FC<Props> = ({
  followAction,
  setFollowAction,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [otherUserIdsText, setOtherUserIdsText] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleClose = () => {
    setOtherUserIdsText('');
    setSuccessMessage('');
    setErrorMessage('');
    setFollowAction(null);
  };

  const handleSave = async () => {
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    if (!followAction) return;

    const { ids: otherUserIds, error } = getUserIdsFromText(otherUserIdsText);

    if (error || !otherUserIds) {
      setErrorMessage(error);
      setIsLoading(false);
      return;
    }

    try {
      await updateFollowingFeeds(
        followAction.mutation,
        followAction.followeeUserIds || otherUserIds,
        followAction.followerUserIds || otherUserIds
      );

      setErrorMessage('');
      setSuccessMessage('Success');
      setOtherUserIdsText('');
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
    }
    setIsLoading(false);
  };

  return (
    <Modal isOpen={!!followAction} onClose={handleClose} minHeight={'auto'}>
      <>
        <Heading2>{followAction?.title}</Heading2>
        <Instructions>{followAction?.instructions}</Instructions>
        <Instructions>Enter user IDs separated by commas e.g.</Instructions>
        <Instructions>
          ccd36fa8-6423-416f-889a-75c473d1a0e4,{' '}
          b9712751-9e05-4a79-bfd6-141dbd85a711,{' '}
          7911b9f2-0f48-4172-9dcc-6642b1cef84b
        </Instructions>
        <StyledTextAreaWrapper>
          <StyledTextArea
            value={otherUserIdsText}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setOtherUserIdsText(e.target.value)
            }
          />
        </StyledTextAreaWrapper>
        <ConfirmationContainer>
          <div>
            {(successMessage || errorMessage) && (
              <ConfirmationMessage>
                {successMessage && (
                  <SuccessMessage>{successMessage}</SuccessMessage>
                )}
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              </ConfirmationMessage>
            )}
          </div>
          <div>
            {!isLoading ? (
              <StyledBlueButton
                data-testid={'follow-relationship-save-button'}
                onClick={handleSave}
              >
                Save
              </StyledBlueButton>
            ) : (
              <Loader small />
            )}
          </div>
        </ConfirmationContainer>
      </>
    </Modal>
  );
};

const Instructions = styled.p`
  margin-bottom: 16px;
`;

const ConfirmationContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTextAreaWrapper = styled.div`
  width: 100%;

  .bx--text-area__wrapper {
    width: 100%;
  }
`;

const StyledTextArea = styled((props) => <TextArea {...props} />)`
  width: 100%;
`;

const ConfirmationMessage = styled.div`
  margin-top: 16px;
`;

const SuccessMessage = styled.p`
  color: green;
`;

const ErrorMessage = styled.p`
  color: red;
`;

export default FollowerRelationshipModal;
