import React, {
  ReactElement,
  useState,
  ChangeEvent,
  SetStateAction,
} from 'react';
import styled from 'styled-components';
import {
  StyledBlueButton,
  StyledRedButton,
  StyledButton,
} from '../../Button/Button';
import CloseIcon from './x_close_icon.svg';
import { Select, SelectItem, TextInput } from 'carbon-components-react';
import { ExerciseAbility, MealPlan } from '../../../types/adminPortalApiSchema';
import { Loader } from '../../Loader/Loader';
import {
  capitalize,
  convertAbilityForEmail,
  convertFromEmailAbilityToAbility,
} from '../../../utils/utils';
import { fetchRecipePacks } from '../../../queries/getRecipePacksQuery';
import { fetchCycles } from '../../../queries/getCyclesQuery';
import { ExerciseAbilityForEmail } from '../../../types/global';

type MealPlanAndCycleData = {
  ability: ExerciseAbility;
  cycleSeqNo: number;
  mealPlan: MealPlan;
  recipePack: number;
  cycleId: string;
};

interface MealPlanDataCallback extends MealPlanAndCycleData {
  ensureRecipeAndCycleContentIsAvailable: () => Promise<boolean>;
}
type Props = {
  headline: string;
  confirmButtonText: string;
  updateMealPlanCallback: (arg0: MealPlanDataCallback) => Promise<void>; // TODO: this!!
  dismissButtonText: string;
  loading: boolean;
  data: MealPlanAndCycleData;
  dismissButtonCallback: () => void;
  dismissToPath?: string | null;
  confirmButtonColor: 'blue' | 'red';
};

export const EditMealPlanAndCycle = ({
  headline,
  data,
  loading,
  confirmButtonText,
  confirmButtonColor,
  updateMealPlanCallback,
  dismissToPath,
  dismissButtonText,
  dismissButtonCallback,
}: Props): ReactElement | null => {
  const dismissDialog: React.MouseEventHandler<HTMLImageElement> = (e) => {
    e.preventDefault();
    dismissButtonCallback();
  };

  const mealPlanOptions: MealPlan[] = [
    'MIXED',
    'PESCATARIAN',
    'VEGETARIAN',
    'VEGAN',
  ];
  const abilityOptions: ExerciseAbilityForEmail[] = [
    'BEGINNER',
    'INTERMEDIATE',
    'ADVANCED',
    'ADVANCED+',
    'GRADUATE',
  ];

  const [mealPlanValue, setMealPlanValue] = useState(data.mealPlan);
  const [abilityValue, setAbilityValue] = useState<ExerciseAbilityForEmail>(
    convertAbilityForEmail(data.ability)
  );
  const [cycleSeqNoValue, setCycleSeqNoValue] = useState(data.cycleSeqNo);

  const [invalidRecipePack, setInvalidRecipePack] = useState<string | null>(
    null
  );
  const [invalidCycle, setInvalidCycle] = useState<string | null>(null);

  const [recipePackValue, setRecipePackValue] = useState(data.recipePack);
  const confirmDialog: React.MouseEventHandler<HTMLImageElement> = async (
    e
  ) => {
    e.preventDefault();
    const ensureRecipeAndCycleContentIsAvailable = async () => {
      const [recipeRes, cycleRes] = await Promise.all([
        fetchRecipePacks(mealPlanValue, recipePackValue),
        fetchCycles(
          convertFromEmailAbilityToAbility(abilityValue),
          cycleSeqNoValue
        ),
      ]);

      // check if the recipe pack exists
      const { data: recipeData, errors: recipeErrors } = recipeRes;
      if (recipeErrors || !recipeData) {
        setInvalidRecipePack(
          `${capitalize(
            mealPlanValue.toLowerCase()
          )} recipe pack number ${recipePackValue} does not exist`
        );
        return false;
      }

      // check if the cycle exists
      const { data: cycleData, errors: cycleErrors } = cycleRes;
      console.log({ cycleRes });
      if (cycleErrors || !cycleData) {
        setInvalidCycle(
          `${capitalize(
            abilityValue.toLowerCase()
          )} cycle number ${cycleSeqNoValue} does not exist`
        );
        return false;
      }

      // Given recipe exists, check it is valid
      const recipePack = recipeData.recipePacks.find(
        (rp) =>
          rp.suitability === mealPlanValue && rp.sequence === recipePackValue
      );
      const recipePackIsValid = Boolean(recipePack);
      const recipePackIsComplete = Boolean(
        recipePack?.generalCount &&
          recipePack?.recipeCount &&
          recipePack?.snackCount
      );
      if (!recipePackIsValid)
        setInvalidRecipePack(
          `${capitalize(
            mealPlanValue.toLowerCase()
          )} recipe pack number ${recipePackValue} does not exist`
        );

      if (recipePackIsValid && !recipePackIsComplete)
        setInvalidRecipePack(
          `${capitalize(
            mealPlanValue.toLowerCase()
          )} recipe pack number ${recipePackValue} is incomplete`
        );

      // Given cycle exists, check it is valid
      const cycle = cycleData.cycles.find(
        (cn) =>
          cn.ability === convertFromEmailAbilityToAbility(abilityValue) &&
          cn.cycleNumber === cycleSeqNoValue
      );
      const cycleNumberIsValid = Boolean(cycle);
      if (!cycleNumberIsValid)
        setInvalidCycle(
          `${capitalize(
            abilityValue.toLowerCase()
          )} cycle number ${cycleSeqNoValue} does not exist`
        );
      if (recipePackIsValid && !recipePackIsComplete)
        setInvalidCycle(
          `${capitalize(
            abilityValue.toLowerCase()
          )} cycle number ${cycleSeqNoValue} is incomplete`
        );

      const validRecipe = recipePackIsValid && recipePackIsComplete;
      return validRecipe && cycleNumberIsValid;
    };
    await updateMealPlanCallback({
      cycleId: data.cycleId,
      mealPlan: mealPlanValue,
      recipePack: recipePackValue,
      ability: convertFromEmailAbilityToAbility(abilityValue),
      cycleSeqNo: cycleSeqNoValue,
      ensureRecipeAndCycleContentIsAvailable,
    });
  };
  return (
    <ConfirmationTakeover>
      <ConfirmationWrapper>
        {loading ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>
            <CloseIconImg
              data-testid={'dismiss-x-icon'}
              onClick={dismissDialog}
              src={CloseIcon}
              alt="Close this dialog"
            ></CloseIconImg>
            <Heading>{headline}</Heading>
            <SelectGroup>
              <StyledSelect
                invalid={false}
                invalidText="You must choose an Exercise ability."
                id="ability"
                name="ability"
                labelText="Ability"
                aria-label="Ability"
                data-testid={'edit-ability'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setInvalidCycle(null);
                  setAbilityValue(
                    e.target.value as SetStateAction<ExerciseAbilityForEmail>
                  );
                }}
              >
                {abilityOptions
                  .map((ability, key) => (
                    <SelectItem
                      key={key}
                      defaultChecked={ability === abilityValue}
                      value={ability}
                      text={capitalize(ability.toLowerCase())}
                    />
                  ))
                  .sort((a) => {
                    return a.props.defaultChecked ? -1 : 1;
                  })}
              </StyledSelect>
              <StyledNumberInput
                invalidText={''}
                data-testid={'number-input'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setInvalidCycle(null);
                  const newVal = e.target.value
                    ? parseInt(parseFloat(e.target.value).toFixed(0))
                    : NaN; // reset to 1 if invalid. This prevents illogical minus numbers being shown in the selector.
                  setCycleSeqNoValue(newVal || 0);
                }}
                labelText="Cycle Number"
                disabled={false}
                type="number"
                id={`Edit cycle number`}
                size={undefined}
                invalid={Boolean(invalidCycle)}
                value={cycleSeqNoValue as number}
              />
            </SelectGroup>
            <SelectGroup>
              <StyledSelect
                invalid={false}
                invalidText="You must choose a Meal Plan."
                id="mealplan"
                name="mealplan"
                labelText="Meal Plan"
                aria-label="Meal Plan"
                data-testid={'newadmin-group-field'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setInvalidRecipePack(null);
                  setMealPlanValue(e.target.value as SetStateAction<MealPlan>);
                }}
              >
                {mealPlanOptions
                  .map((mealPlan, key) => (
                    <SelectItem
                      key={key}
                      defaultChecked={mealPlan === mealPlanValue}
                      value={mealPlan}
                      text={capitalize(mealPlan.toLowerCase())}
                    />
                  ))
                  .sort((a) => {
                    return a.props.defaultChecked ? -1 : 1;
                  })}
              </StyledSelect>
              <StyledNumberInput
                invalidText={''}
                data-testid={'number-input'}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                  setInvalidRecipePack(null);
                  const newVal = e.target.value
                    ? parseInt(parseFloat(e.target.value).toFixed(0))
                    : NaN; // reset to 1 if invalid. This prevents illogical minus numbers being shown in the selector.
                  setRecipePackValue(newVal || 0);
                }}
                labelText="Recipe Pack"
                disabled={false}
                type="number"
                id={`Edit recipe pack`}
                size={undefined}
                invalid={Boolean(invalidRecipePack)}
                value={recipePackValue as number}
              />
            </SelectGroup>
            {invalidCycle && <InvalidWarning>{invalidCycle}</InvalidWarning>}
            {invalidRecipePack && (
              <InvalidWarning>{invalidRecipePack}</InvalidWarning>
            )}
            <ButtonWrapper>
              <ConfirmButton
                confirmButtonColor={confirmButtonColor}
                data-testid={'confirm-button'}
                disabled={loading || !!invalidRecipePack || !!invalidCycle}
                onClick={confirmDialog}
                kind="secondary"
                size="default"
                tabIndex={0}
                type="button"
              >
                {confirmButtonText}
              </ConfirmButton>
              <DismissButton
                data-testid={'dismiss-button'}
                onClick={dismissDialog}
                kind="primary"
                size="default"
                tabIndex={0}
                type="button"
                href={dismissToPath}
              >
                {dismissButtonText}
              </DismissButton>
            </ButtonWrapper>
          </>
        )}
      </ConfirmationWrapper>
    </ConfirmationTakeover>
  );
};

export const StyledConfirmButton = styled((props) => (
  <StyledBlueButton {...props} />
))`
  margin: 0;
  font-weight: bold;
  width: 100%;
  margin: 10px 0px;
`;

const StyledNumberInput = styled((props) => <TextInput {...props} />)`
  width: 200%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const StyledSelect = styled((props) => <Select {...props} />)`
  width: 70%;
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
  margin-right: 10px;
`;

export const Padder = styled.div`
  margin-bottom: ${(props): string => props.theme.contentMarginBottom};
`;

export const ButtonPadder = styled.div`
  margin-bottom: 10px;
`;

const Heading = styled.h2`
  font-weight: normal;
  font-size: ${(props): string => props.theme.l};
  margin: 50px 0px 20px 0px;
`;

// We use z-index to make sure the takeover window sits above any other window content
const ConfirmationTakeover = styled.div`
  display: flex;
  z-index: ${(props): string => props.theme.confirmationTakeover};
  justify-content: center;
  align-items: center;
  background: rgba(57, 57, 57, 0.3);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 20px 50px;
  border: 1px solid ${(props): string => props.theme.grey};
  border-radius: 15px;
  background: ${(props): string => props.theme.white};
  height: 500px;
  width: 422px;
  position: relative;
`;

const ConfirmButton = styled(({ confirmButtonColor, ...rest }) => {
  type Options = {
    blue: JSX.Element;
    red: JSX.Element;
  };

  const options: Options = {
    blue: <StyledBlueButton {...rest} />,
    red: <StyledRedButton {...rest} />,
  };

  return (
    options[confirmButtonColor as keyof Options] || (
      <StyledBlueButton {...rest} />
    )
  );
})`
  width: 100%;
  height: 48px;
`;

const DismissButton = styled((props) => <StyledButton {...props} />)`
  width: 100%;
  height: 48px;
  margin: 10px 0px 50px 0px;
`;

const CloseIconImg = styled.img`
  position: absolute;
  right: 20px;
  cursor: pointer;
`;

const SelectGroup = styled.div`
  display: flex;
`;

const InvalidWarning = styled.p`
  color: ${(props): string => props.theme.warningRed}
  text-align: left;
  margin-bottom: 1em;
`;
